import type { NextPage } from 'next'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { Header } from 'components'
import HeroBanner from '@/components/@common/Hero'
import { client } from 'providers'
import { pageDetailQuery } from 'queries'
import { Page } from 'types/schema'

const Home: NextPage<{ data: Page }> = ({ data }) => {
    const { t } = useTranslation('common');

    const { bannerMovie } = data

    return (
        <div className="flex flex-col min-h-screen overflow-hidden bg-slate-900">
            <Head>
                <title>{t("meta.title")}</title>
                <meta name="description" content={t("meta.description")} />
                <link rel="icon" href="/favicon.svg" />
            </Head>

            <Header />

            <HeroBanner playbackId={bannerMovie.playbackId} type="404" />

        </div >
    )
}

export const getStaticProps = async ({ locale }: { locale: string }) => {
    // Fetch data from external API
    const data = await client.fetch(pageDetailQuery, { slug: 'home' })

    // Pass data to the page via props
    return { props: { data, ...await serverSideTranslations(locale, ['common']), } }
}


export default Home
